import reactContainerFactory from '@/components/react-container/reactContainerFactory';
import { lazy } from 'react';
const returnInbound = {
    path: '/returninbound',
    name: 'returnInbound',
    meta: {
        title: 'Return Inbound',
        icon: 'Return Inbound',
    },
    redirect: '/returninbound/order',
    component: () => import('@/views/layout/index.vue'),
    children: [
        {
            path: 'orderasn',
            name: 'returnInbound.orderasn',
            meta: {
                title: 'Order',
            },
            redirect: '/returninbound/order',
            component: () => import('../../views/layout/subPage.vue'),
        },
        {
            path: 'order',
            name: 'PC.ReturnInbound.Order.ReturnASN.View',
            meta: {
                title: 'ASN',
                menuHide: true,
                filterSave: true,
            },
            component: () => import('../../views/rms/order/asn/index.vue'),
        },
        {
            path: 'queuelist',
            name: 'PC.ReturnInbound.Order.QueueList.View',
            meta: {
                menuHide: true,
                title: 'Queue List',
                icon: '',
            },
            component: () => import('../../views/rms/queue-list/index.vue'),
        },
        {
            path: 'order/detail/:id',
            name: 'returnInbound.order.detail',
            meta: {
                icon: '',
                title: 'Detail',
                menuHide: true,
                navList: [
                    {
                        routeName: 'returnInbound',
                        title: 'Return Inbound',
                    },
                    {
                        routeName: 'PC.ReturnInbound.Order.ReturnASN.View',
                        title: 'Order',
                    },
                ],
            },
            component: () => import('../../views/rms/order/asn/detail.vue'),
        },
        {
            path: 'order/create/inbound_type=:return_inbound_type&return_reason=:return_reason',
            name: 'returnInbound.order.create',
            meta: {
                icon: '',
                title: 'Create',
                menuHide: true,
                navList: [
                    {
                        routeName: 'returnInbound',
                        title: 'Return Inbound',
                    },
                    {
                        routeName: 'PC.ReturnInbound.Order.ReturnASN.View',
                        title: 'Order',
                    },
                ],
            },
            component: () => import('../../views/rms/order/hand-create/index.vue'),
        },
        {
            path: 'task',
            name: 'returnInbound.task',
            meta: {
                title: 'Task',
            },
            redirect: '/returninbound/qctask',
            component: () => import('../../views/layout/subPage.vue'),
        },
        {
            path: 'qctask',
            name: 'PC.ReturnInbound.Task.QCTask.View',
            meta: {
                menuHide: true,
                title: 'QC Task',
                filterSave: true,
            },
            component: () => import('../../views/rms/task/qc-task/qc-task.vue'),
        },
        {
            path: 'qctaskdetail',
            name: 'returnInbound.qcTaskDetail',
            meta: {
                title: 'QC Task Detail',
                menuHide: true,
                navList: [
                    {
                        routeName: 'returnInbound',
                        title: 'Return Inbound',
                    },
                    {
                        routeName: 'PC.ReturnInbound.Task.QCTask.View',
                        title: 'Inbound Task',
                    },
                ],
            },
            component: () => import('../../views/rms/task/qc-task/qc-task-detail.vue'),
        },
        {
            path: 'judgingtask',
            name: 'PC.ReturnInbound.Task.JudgingTask.View',
            meta: {
                menuHide: true,
                title: 'Judging Task',
                filterSave: true,
            },
            component: () => import('../../views/rms/task/judging-task/judging-task.vue'),
        },
        {
            path: 'judgingtaskdetail',
            name: 'returnInbound.judgingTaskDetail',
            meta: {
                title: 'Judging Task Detail',
                menuHide: true,
                navList: [
                    {
                        routeName: 'returnInbound',
                        title: 'Return Inbound',
                    },
                    {
                        routeName: 'PC.ReturnInbound.Task.JudgingTask.View',
                        title: 'Judging Task',
                    },
                ],
            },
            component: () => import('../../views/rms/task/judging-task/judging-task-detail.vue'),
        },
        {
            path: 'receivetask',
            name: 'PC.ReturnInbound.Task.ReceiveTask.View',
            meta: {
                menuHide: true,
                title: 'Receiving Task',
                filterSave: true,
            },
            component: () => import('../../views/rms/task/receive-task/receive-task.vue'),
        },
        {
            path: 'receivetaskdetail',
            name: 'returninbound.receiveTaskDetail',
            meta: {
                title: 'Receiving Task Detail',
                menuHide: true,
                navList: [
                    {
                        routeName: 'returnInbound',
                        title: 'Return Inbound',
                    },
                    {
                        routeName: 'PC.ReturnInbound.Task.ReceiveTask.View',
                        title: 'Receiving Task',
                    },
                ],
            },
            component: () => import('../../views/rms/task/receive-task/receive-task-detail.vue'),
        },
        {
            path: 'arrving',
            name: 'returnInbound.arriving',
            meta: {
                title: 'Arriving',
                icon: '',
            },
            component: () => import('../../views/rms/arriving/index.vue'),
        },
        {
            path: 'qcReceiving',
            name: 'returnInbound.qcReceiving',
            meta: {
                title: 'QC&Receiving',
                icon: '',
            },
            component: () => import('../../views/rms/qc-receiving/qc.vue'),
        },
        {
            path: 'qc',
            name: 'returnInbound.qc',
            meta: {
                title: 'QC',
                icon: '',
            },
            component: () => import('../../views/rms/qc/qc.vue'),
        },
        {
            path: 'judgement',
            name: 'returnInbound.judging',
            meta: {
                title: 'Judging',
            },
            component: () => import('../../views/rms/judgement/index.vue'),
        },
        {
            path: 'disputeprocessing',
            name: 'returnInbound.disputeprocessing',
            meta: {
                title: 'Dispute Processing',
                icon: '',
            },
            component: reactContainerFactory({
                name: 'returnInbound.disputeprocessing',
            }),
            props: {
                component: () => lazy(() => import('@/react-app/pages/return-inbound/dispute-processing/index')),
            },
        },
        {
            path: 'rimassreceive',
            // name: 'PC.ReturnInbound.Receiving',
            name: 'returnInbound.receiving',
            meta: {
                title: 'Receiving',
                icon: '',
            },
            component: () => import('../../views/rms/receive/index.vue'),
        },
        {
            path: 'ristandardputaway',
            name: 'returnInbound.standardPutaway',
            meta: {
                title: 'Standard Putaway',
                icon: '',
            },
            component: () => import('../../views/rms/standard-putaway/standard-putaway.vue'),
        },
        {
            path: 'rimassputaway',
            name: 'returnInbound.rimassPutaway',
            meta: {
                title: 'Mass Putaway',
                icon: '',
            },
            component: () => import('../../views/rms/mass-putaway/mass-putaway.vue'),
        },
        {
            path: 'putawaytask',
            name: 'PC.ReturnInbound.Task.PutawayTask.View',
            meta: {
                title: 'Putaway Task',
                menuHide: true,
                filterSave: true,
                navList: [
                    {
                        routeName: 'returnInbound',
                        title: 'Return Inbound',
                    },
                ],
            },
            component: () => import('../../views/rms/task/putaway-task/putaway-task.vue'),
        },
        //  3pl 索赔列表页
        {
            path: 'threeplclaim',
            name: 'returnInbound.3PLClaim',
            // name:'PC.ReturnInbound.3PLClaim.View',
            meta: {
                title: '3PL Claim',
                filterSave: true,
                navList: [
                    {
                        routeName: 'returnInbound',
                        title: 'Return Inbound',
                    },
                ],
            },
            component: () => import('../../views/rms/threepl-claim/index.vue'),
        },
        // 3PL 索赔详情页
        {
            path: 'threeplclaim/detail',
            name: 'returnInbound.3PLClaim.View',
            // name:'PC.ReturnInbound.3PLClaim.View',
            meta: {
                title: '3PL Claim',
                filterSave: true,
                menuHide: true,
                navList: [
                    {
                        routeName: 'returnInbound',
                        title: 'Return Inbound',
                    },
                    {
                        routeName: 'returnInbound.3PLClaim',
                        title: '3PL Claim',
                    },
                    // 修改
                ],
            },
            component: () => import('../../views/rms/threepl-claim/detail.vue'),
        },
        // 3PL 索赔编辑页
        {
            path: 'threeplclaim/edit',
            name: 'PC.ReturnInbound.3PLClaim.Action.Claim',
            meta: {
                title: '3PL Claim',
                filterSave: true,
                menuHide: true,
                navList: [
                    {
                        routeName: 'returnInbound',
                        title: 'Return Inbound',
                    },
                    {
                        routeName: 'returnInbound.3PLClaim',
                        title: '3PL Claim',
                    },
                ],
            },
            component: () => import('../../views/rms/threepl-claim/claim-edit.vue'),
        },
        {
            path: 'returnToBuyer',
            name: 'returnInbound.returnToBuyer',
            meta: {
                title: 'Return to Buyer',
                icon: '',
                filterSave: true,
            },
            component: () => import('../../views/rms/return-buyer/index.vue'),
        },
        {
            path: 'returnToBuyer/detail',
            name: 'returnInbound.returnToBuyer.detail',
            meta: {
                title: 'Return to Buyer View',
                menuHide: true,
                icon: '',
            },
            component: () => import('../../views/rms/return-buyer/detail.vue'),
        },
        // 新增 return to seller 路由
        {
            path: 'returnToSeller',
            name: 'returnInbound.returnToSeller',
            component: () => import('@/views/layout/subPage.vue'),
            meta: {
                title: 'Return to Seller',
                icon: '',
            },
            redirect: '/returninbound/returnToSeller/order',
            children: [
                {
                    path: 'order',
                    name: 'returnInbound.returnToSeller.order',
                    meta: {
                        title: 'Order',
                        filterSave: true,
                    },
                    redirect: '/returninbound/returnToSeller/orderList',
                    component: () => import('../../views/rms/return-to-seller/index.vue'),
                },
                {
                    path: 'orderList',
                    name: 'PC.ReturnInbound.ReturnToSeller.Order.Action.View',
                    meta: {
                        title: 'Order',
                        filterSave: true,
                        menuHide: true,
                    },
                    component: () => import('../../views/rms/return-to-seller/index.vue'),
                },
                {
                    path: 'carton',
                    name: 'PC.ReturnInbound.ReturnToSeller.Order.Carton.View',
                    meta: {
                        title: 'Carton',
                        filterSave: true,
                        menuHide: true,
                    },
                    component: () => import('../../views/rms/return-to-seller/index.vue'),
                },
                {
                    path: 'cartonDetail',
                    name: 'returnInbound.returnToSeller.cartonDetail',
                    meta: {
                        title: 'Carton Detail',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'returnInbound.returnToSeller.carton',
                                title: 'Carton',
                            },
                        ],
                    },
                    component: () => import('../../views/rms/return-to-seller/carton/detail.vue'),
                },
                {
                    path: 'task',
                    name: 'returnInbound.returnToSeller.task',
                    meta: {
                        title: 'Task',
                    },
                    redirect: '/returninbound/returnToSeller/packing',
                    component: () => import('@/views/layout/subPage.vue'),
                },
                {
                    path: 'packing',
                    name: 'PC.ReturnInbound.ReturnToSeller.Task.PackingTask.View',
                    meta: {
                        title: 'Packing Task',
                        menuHide: true,
                        filterSave: true,
                        navList: [
                            {
                                routeName: 'returnInbound.orderasn',
                                title: 'Return Inbound',
                            },
                            {
                                routeName: 'returnInbound.returnToSeller',
                                title: 'Return to Seller',
                            },
                            {
                                routeName: 'returnInbound.returnToSeller.task',
                                title: 'Task',
                            },
                        ],
                    },
                    component: () => import('../../views/rms/return-to-seller/task/index.vue'),
                },
                {
                    path: 'packingDetail',
                    name: 'returnInbound.returnToSeller.packingTaskDetail',
                    meta: {
                        title: 'Task Detail',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'returnInbound.orderasn',
                                title: 'Return Inbound',
                            },
                            {
                                routeName: 'returnInbound.returnToSeller',
                                title: 'Return to Seller',
                            },
                            {
                                routeName: 'returnInbound.returnToSeller.task',
                                title: 'Task',
                            },
                            {
                                routeName: 'PC.ReturnInbound.ReturnToSeller.Task.PackingTask.View',
                                title: 'Packing Task',
                            },
                        ],
                    },
                    component: () => import('../../views/rms/return-to-seller/task/packing/detail.vue'),
                },
                {
                    path: 'packingOperation',
                    name: 'returnInbound.returnToSeller.packingTaskOperation',
                    meta: {
                        title: 'Packing',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'returnInbound.orderasn',
                                title: 'Return Inbound',
                            },
                            {
                                routeName: 'returnInbound.returnToSeller',
                                title: 'Return to Seller',
                            },
                            {
                                routeName: 'returnInbound.returnToSeller.task',
                                title: 'Task',
                            },
                            {
                                routeName: 'PC.ReturnInbound.ReturnToSeller.Task.PackingTask.View',
                                title: 'Packing Task',
                            },
                        ],
                    },
                    component: () => import('../../views/rms/return-to-seller/task/packing/detail.vue'),
                },
                {
                    path: 'shipping',
                    name: 'PC.ReturnInbound.ReturnToSeller.Task.ShippingTask.View',
                    meta: {
                        title: 'Shipping Task',
                        menuHide: true,
                        filterSave: true,
                        navList: [
                            {
                                routeName: 'returnInbound.orderasn',
                                title: 'Return Inbound',
                            },
                            {
                                routeName: 'returnInbound.returnToSeller',
                                title: 'Return to Seller',
                            },
                            {
                                routeName: 'returnInbound.returnToSeller.task',
                                title: 'Task',
                            },
                        ],
                    },
                    component: () => import('../../views/rms/return-to-seller/task/index.vue'),
                },
                {
                    path: 'shippingDetail',
                    name: 'returnInbound.returnToSeller.shippingTaskDetail',
                    meta: {
                        title: 'Task Detail',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'returnInbound.orderasn',
                                title: 'Return Inbound',
                            },
                            {
                                routeName: 'returnInbound.returnToSeller',
                                title: 'Return to Seller',
                            },
                            {
                                routeName: 'returnInbound.returnToSeller.task',
                                title: 'Task',
                            },
                            {
                                routeName: 'PC.ReturnInbound.ReturnToSeller.Task.ShippingTask.View',
                                title: 'Shipping Task',
                            },
                        ],
                    },
                    component: () => import('../../views/rms/return-to-seller/task/shipping/detail.vue'),
                },
            ],
        },
        {
            path: 'returnToSeller/detail',
            name: 'returnInbound.returnToSeller.detail',
            meta: {
                title: 'Detail',
                icon: '',
                filterSave: true,
                menuHide: true,
                navList: [
                    {
                        routeName: 'returnInbound.orderasn',
                        title: 'Return Inbound',
                    },
                    {
                        routeName: 'returnInbound.returnToSeller',
                        title: 'Return to Seller',
                    },
                    {
                        routeName: 'returnInbound.returnToSeller.order',
                        title: 'Order',
                    },
                ],
            },
            component: () => import('../../views/rms/return-to-seller/detail.vue'),
        },
        {
            path: 'putawaytask/edit',
            name: 'returnInbound.putawayTask.edit',
            meta: {
                title: 'Putaway Task Edit',
                menuHide: true,
                navList: [
                    {
                        routeName: 'returnInbound',
                        title: 'Return Inbound',
                    },
                    {
                        routeName: 'PC.ReturnInbound.Task.PutawayTask.View',
                        title: 'Putaway Task',
                    },
                ],
            },
            component: () => import('../../views/rms/task/putaway-task/putaway-task-detail.vue'),
        },
        {
            path: 'putawaytask/detail',
            name: 'returnInbound.putawayTask.detail',
            meta: {
                title: 'Putaway Task Detail',
                menuHide: true,
                navList: [
                    {
                        routeName: 'returnInbound',
                        title: 'Return Inbound',
                    },
                    {
                        routeName: 'PC.ReturnInbound.Task.PutawayTask.View',
                        title: 'Putaway Task',
                    },
                ],
            },
            component: () => import('../../views/rms/task/putaway-task/putaway-task-detail.vue'),
        },
        {
            path: 'liquidation',
            name: 'returnInbound.liquidation',
            meta: {
                title: 'Liquidation',
            },
            redirect: '/returninbound/liquidation',
            component: () => import('../../views/layout/subPage.vue'),
            children: [
                {
                    path: 'order',
                    name: 'returnInbound.liquidation.order',
                    meta: {
                        title: 'Order',
                    },
                    component: reactContainerFactory({
                        name: 'returnInbound.liquidation.order',
                    }),
                    props: {
                        component: () => lazy(() => import('@/react-app/pages/rms/liquidation/order')),
                    },
                },
                {
                    path: 'order-detail/:id',
                    name: 'returnInbound.liquidation.order.detail',
                    meta: {
                        title: 'Detail',
                        menuHide: true,
                    },
                    component: reactContainerFactory({
                        name: 'returnInbound.liquidation.order.detail',
                    }),
                    props: {
                        component: () => lazy(() => import('@/react-app/pages/rms/liquidation/order/detail')),
                    },
                },
                {
                    path: 'task',
                    name: 'returnInbound.liquidation.task',
                    meta: {
                        title: 'Task',
                    },
                    redirect: '/returninbound/liquidation/shippingtask',
                    component: () => import('../../views/layout/subPage.vue'),
                },
                {
                    path: 'shippingtask',
                    name: 'PC.ReturnInbound.Liquidation.Task.ShippingTask',
                    meta: {
                        menuHide: true,
                        title: 'Shipping Task',
                    },
                    component: reactContainerFactory({
                        name: 'PC.ReturnInbound.Liquidation.Task.ShippingTask',
                    }),
                    props: {
                        component: () => lazy(() => import('@/react-app/pages/rms/liquidation/task')),
                    },
                },
                {
                    path: 'shippingtaskdetail/:id',
                    name: 'PC.ReturnInbound.Liquidation.Task.ShippingTask.View',
                    meta: {
                        menuHide: true,
                        title: 'Detail',
                        navList: [
                            {
                                routeName: 'returnInbound',
                                title: 'Return Inbound',
                            },
                            {
                                routeName: 'PC.ReturnInbound.Liquidation.Task.ShippingTask',
                                title: 'Shipping Task',
                            },
                        ],
                    },
                    component: reactContainerFactory({
                        name: 'PC.ReturnInbound.Liquidation.Task.ShippingTask.View',
                    }),
                    props: {
                        component: () => lazy(() => import('@/react-app/pages/rms/liquidation/task/components/shipping-task/shipping-task-detail')),
                    },
                },
            ],
        },
    ],
};
export default returnInbound;
