// 不需要转换的固定字符
const IGNORE_TRANS_TEXT_LIST = [
    'SKU',
    'ID',
    'ASN',
    'PDA',
    'MTO',
    'UID',
    'SA',
    'WMS',
    'RT',
    'UPC',
    'WHS',
    'LM',
    'TN',
    'FBS',
    'OMS',
    'PMS',
    'RTS',
    'YES',
    'NO',
    'PC',
    'CC',
    'OB',
    'RI',
    'RTB',
    'CC',
    'OB',
    'CB',
    'OOS',
    'QC',
    'AQL',
    'SBS',
    'B2B2C',
    'B2C',
    'BD',
    'MT',
    'Non-SBS',
    '3PL',
    'ABC',
    'MTB',
    'IBT',
    'FRS',
    'SCS',
];
const ALL_LOWER_TEXT_LIST = ['OF'];
export const capitalizeSingle = (s) => {
    if (!s)
        return s;
    if (s.length === 1) {
        return s.toUpperCase();
    }
    if (IGNORE_TRANS_TEXT_LIST.includes(s)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return IGNORE_TRANS_TEXT_LIST.find((item) => item === s);
    }
    else if (ALL_LOWER_TEXT_LIST.includes(s)) {
        return s.toLowerCase();
    }
    return s[0].toUpperCase() + s.substring(1).toLowerCase();
};
export default (s) => {
    if (!s)
        return s;
    const arr = s.split(/[\s|_]+/);
    return arr.map(capitalizeSingle).join(' ');
};
