const linehaultrip = {
    path: '/linehaul',
    name: 'linehaul',
    meta: {
        title: 'LineHaul',
        icon: 'Inbound',
    },
    redirect: '/linehaul/hubLinehaulTrips/trip',
    component: () => import('@/views/layout/index.vue'),
    children: [
        {
            path: '/lh/trip',
            name: 'linehaul.linehaultrip',
            meta: {
                title: 'Linehaul Trips',
            },
            redirect: '/linehaul/hubLinehaulTrips/trip',
        },
    ],
};
export default linehaultrip;
