import { __decorate } from "tslib";
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import permission from '../../api/basic/permission';
import routesConfig from '@/router/config';
import { loginRoutesConfig } from '@/router/login-route.config';
import { tabNames } from '@/common/tab-names';
// import { RouteConfig } from 'vue-router';
import { registerPermissions } from '@spx-portal/shared-permissions';
function getMenuHideRouters(routers, menuHideRouterNames) {
    for (const index in routers) {
        menuHideRouterNames.push(routers[index].name);
        if ('children' in routers[index]) {
            getMenuHideRouters(routers[index].children, menuHideRouterNames);
        }
    }
}
function search(routers, menuHideRouterNames) {
    for (const index in routers) {
        if (routers[index].meta.menuHide) {
            menuHideRouterNames.push(routers[index].name);
            if ('children' in routers[index]) {
                getMenuHideRouters(routers[index].children, menuHideRouterNames);
            }
        }
        else {
            if ('children' in routers[index]) {
                search(routers[index].children, menuHideRouterNames);
            }
        }
    }
}
let PermissionMenu = class PermissionMenu extends VuexModule {
    constructor() {
        super(...arguments);
        // 接口返回的有权限展示的菜单栏的name的集合
        this.permissionMenu = [];
        // 接口返回的用户权限id集合
        this.userPermission = [];
        // 所有可渲染的页面路由
        this.routers = loginRoutesConfig;
        // 除loginRoutesConfig之外所有可渲染的页面路由
        this.addRouters = [];
        // all tab page names: {<one tab page name>: [the rest tab page names for one menu, ...], ...}
        this.tabPageNamesMap = {};
        // @Action({ commit: 'setRoutes', rawError: true })
        // generateRoutes() {
        //   const accessedRouters = filterAsyncRouter(
        //     routesConfig,
        //     this.permissionMenu
        //   );
        //   return accessedRouters;
        // }
    }
    setPermissionMenu(data = []) {
        this.permissionMenu = data;
        const menuHideRouterNames = [];
        search(routesConfig, menuHideRouterNames);
        this.addRouters = menuHideRouterNames;
    }
    setUserPermission(data = []) {
        this.userPermission = data;
    }
    setRoutes(routers = []) {
        this.addRouters = routers;
        this.routers = loginRoutesConfig.concat(routers);
    }
    setTabPageNames(tabNames = []) {
        const m = {};
        tabNames.forEach((arr) => {
            arr.forEach((name) => {
                m[name] = arr;
            });
        });
        this.tabPageNamesMap = m;
    }
    async loadPermissionMenu() {
        const resp = await permission.getPermissionMenu();
        return resp.data.list;
    }
    async loadUserPermission() {
        const resp = await permission.getUserPermission();
        registerPermissions(() => new Set(resp.data.list || []));
        return resp.data.list;
    }
    async loadTabPageNames() {
        return tabNames;
    }
};
__decorate([
    Mutation
], PermissionMenu.prototype, "setPermissionMenu", null);
__decorate([
    Mutation
], PermissionMenu.prototype, "setUserPermission", null);
__decorate([
    Mutation
], PermissionMenu.prototype, "setRoutes", null);
__decorate([
    Mutation
], PermissionMenu.prototype, "setTabPageNames", null);
__decorate([
    Action({ commit: 'setPermissionMenu', rawError: true })
], PermissionMenu.prototype, "loadPermissionMenu", null);
__decorate([
    Action({ commit: 'setUserPermission', rawError: true })
], PermissionMenu.prototype, "loadUserPermission", null);
__decorate([
    Action({ commit: 'setTabPageNames', rawError: true })
], PermissionMenu.prototype, "loadTabPageNames", null);
PermissionMenu = __decorate([
    Module
], PermissionMenu);
export default PermissionMenu;
