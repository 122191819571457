import { format } from './date';
import { getRenderValue, safeGet } from '@/utils/safeGet';
import capitalizeSingle from '@/utils/capitalize';
import { i18n } from '@/i18n/index';
import store from '../store/index';
import logger from './logger';
import { DEFAULT_NULL_OR_EMPTY_STRING } from '@/common/constant';
import { getCookie } from './cookie';
import html2canvas from 'html2canvas';
import printer from 'wms-printer';
import { getDevConf } from '@/api/inbound/counting';
import { getSafeSelectOptions } from '@/utils/safeGet';
import { searchABCConfig } from '@/api/rule-center/abcLevel';
import { getParkZoneTree } from '@/api/inventory-management/cycle-count-order';
//整形数字格式化：123456 -> 123,456
export const intFormat = (num) => {
    if (!num) {
        return 0;
    }
    const reg = /(?=(\B)(\d{3})+$)/g;
    return num.toString().replace(reg, ',');
};
//错误上报
export const errorReport = (e) => {
    logger.error(e?.message || e);
};
export function validate(refName) {
    return new Promise((resolve, reject) => {
        this.$refs[refName].validate(async (valid) => {
            if (valid) {
                resolve(true);
            }
            else {
                this.$nextTick(() => {
                    const errorList = document.getElementsByClassName('ssc-form-item-error');
                    errorList.length > 0 &&
                        errorList[0].scrollIntoView({
                            block: 'nearest',
                            behavior: 'smooth',
                        });
                    reject(false);
                });
            }
        });
    });
}
// 对部分表单字段进行校验的方法
export function validateField(refName, props) {
    return new Promise((resolve, reject) => {
        this.$refs[refName].validateField(props, async (errorMessage) => {
            if (!errorMessage) {
                resolve(true);
            }
            else {
                this.$nextTick(() => {
                    const errorList = document.getElementsByClassName('ssc-form-item-error');
                    errorList.length > 0 &&
                        errorList[0].scrollIntoView({
                            block: 'nearest',
                            behavior: 'smooth',
                        });
                });
                reject(false);
            }
        });
    });
}
//object，通过value 查找key
export const getObjKeyByValue = (value, obj) => {
    let key = '';
    Object.entries(obj).some(([itemKey, itemVal]) => {
        if (itemVal === value) {
            key = itemKey;
            return true;
        }
    });
    return key;
};
//去除字符串前后空格
export const trim = (str) => {
    return str.replace(/^\s+|\s+$/g, '');
};
// 格式化转换指定空值 => '-'
export function formatIfEmptyText(value, isDate = false) {
    const emptyList = [undefined, null, ''];
    if (isDate) {
        emptyList.push(0);
    }
    if (emptyList.includes(value)) {
        return DEFAULT_NULL_OR_EMPTY_STRING;
    }
    return value;
}
/**
 * 按照固定规则转换表格cell数据
 * @param  {Record<string, any>} curRowData - 本行数据
 * @param  {prop: string; selections?: object; filterType?: string} curColumnSetting - 本列设置项
 */
export function formatTableCellText(curRowData, curColumnSetting, default_text, formatType = 'second') {
    /* const DEFAULT_NULL_TEXT =
      typeof default_text !== 'undefined'
        ? default_text
        : DEFAULT_NULL_OR_EMPTY_STRING; */
    const DEFAULT_NULL_TEXT = formatIfEmptyText(default_text);
    const { prop: key, selections, filterType } = curColumnSetting;
    // 根据 select key 渲染对应 label 值
    if (selections) {
        if (Array.isArray(selections)) {
            const targetItem = selections.find((item) => {
                if (item.value === curRowData[key]) {
                    return true;
                }
            });
            return ((targetItem && targetItem['label']) ||
                curRowData[key] ||
                DEFAULT_NULL_TEXT);
        }
        else {
            return (getObjKeyByValue(curRowData[key], selections) ||
                curRowData[key] ||
                DEFAULT_NULL_TEXT);
        }
    }
    /* eslint-disable */
    switch (filterType) {
        case 'date':
        case 'dateRange':
        case 'dateTimerRange': {
            // 后端的 utc 0 时区时间，前端直接 new Date(date)就可以, 不需要转仓库时区
            const useUtcZeroTimeProps = [
                'expiration_date',
                'production_date',
                'inbound_date',
            ];
            const isUTCZero = useUtcZeroTimeProps.includes(key);
            const value = format(curRowData[key], formatType, isUTCZero);
            const is_use_defalut_text = value === undefined || value === '' || value === null;
            return !is_use_defalut_text ? value : DEFAULT_NULL_TEXT;
        }
        default: {
            const value = curRowData[key];
            const is_use_defalut_text = value === undefined || value === '' || value === null;
            return !is_use_defalut_text ? value : DEFAULT_NULL_TEXT;
        }
    }
    /* eslint-enable */
}
// 判断是否是数组
export function isArr(arr) {
    return arr instanceof Array;
}
export function isObj(...objs) {
    for (const obj of objs) {
        if (Object.prototype.toString.call(obj).slice(8, -1) !== 'Object')
            return false;
    }
    return true;
}
export function reverseObj(obj) {
    if (!isObj(obj))
        return obj;
    const tmp = {};
    Object.keys(obj).forEach((key) => {
        tmp[obj[key]] = key;
    });
    return tmp;
}
//优化请求对象
export function compactObj(obj) {
    if (!isObj(obj))
        return obj;
    Object.keys(obj).forEach((key) => {
        if (!obj[key] && obj[key] !== 0) {
            delete obj[key];
        }
        if (typeof obj[key] === 'string') {
            obj[key] = obj[key].replace(/^(\s)+|(\s)+$/g, '');
        }
    });
    return obj;
}
export function enumsName(enumsKey, value) {
    const _obj = safeGet(store.state.enums, `systemEnums.${enumsKey}`) || {};
    const key = getObjKeyByValue(value, _obj);
    const val = capitalizeSingle(key);
    const res = val === '' ? '-' : i18n(val);
    return res;
}
export function enumsNameByOptions(options, value) {
    const key = getObjKeyByValue(value, options);
    return i18n(capitalizeSingle(key));
}
export function getIsDate(obj) {
    return Object.prototype.toString.call(obj) === '[object Date]';
}
// 是否为正整数
export function isPositiveInteger(num) {
    const regexp = /^[0-9]*[1-9][0-9]*$/;
    return regexp.test(`${num || ''}`);
}
// 获取当前仓库所属地区的名字
export function getCurrentRegionName() {
    const whs = getCookie('setting_whs_v2') || '';
    const region = whs.slice(0, 2) || '';
    return region;
}
// 获取是否当前仓库为台湾仓库
export function getIsTWWhs() {
    const whs = getCookie('setting_whs_v2') || '';
    const match = whs.search('TW') >= 0;
    return match;
}
// 获取是否当前仓库为CN仓库
export function getIsCNWhs() {
    const whs = getCookie('setting_whs_v2') || '';
    const match = whs.search('CN') === 0;
    return match;
}
const getCurRegion = (region) => {
    const whs = getCookie('setting_whs_v2') || '';
    const match = whs.search(region) === 0;
    return match;
};
export const IS_ID = getCurRegion('ID'); // Indonesia
export const IS_MY = getCurRegion('MY'); // Malaysia
export const IS_TH = getCurRegion('TH'); // Thailand
export const IS_PH = getCurRegion('PH'); // Philippines
export const IS_VN = getCurRegion('VN'); // Vietnam
export const IS_SG = getCurRegion('SG'); // Singapore
export const IS_TW = getCurRegion('TW'); // 中国台湾
export const IS_BR = getCurRegion('BR'); // 巴西
export const IS_CN = getCurRegion('CN'); // 中国
export const IS_MX = getCurRegion('MX'); // 墨西哥
/**
 * 计算字符串的字节长度
 *
 * @param {string} str - 需要计算字节长度的字符串
 */
export const getByteLength = (str) => {
    return new TextEncoder().encode(str).length;
};
/**
 * 表单多选处理
 * 处理多选逻辑，ALL 置为空字符串，选择 ALL 时，取消其他选项，选择其他选项时，可以多选
 *
 * @param list 已经选中的下拉选项列表
 * @param ruleForm 需要处理的表单
 * @param key 表单中，需要处理的下拉值，当前的 key
 */
export const formMultiSelectedChange = (list, ruleForm, key, allValue = -1) => {
    list.some((item, index) => {
        if ((item === '' || item === -1 || item === allValue) && index !== 0) {
            ruleForm[key] = [item];
            return true;
        }
        else if ((item === '' || item === -1 || item === allValue) &&
            index === 0 &&
            list.length > 1) {
            ruleForm[key] = list.slice(1);
            return true;
        }
    });
};
/**
 * 过滤组件下拉筛选 ALL 选项处理
 *
 * @param ref 过滤组件，比如 this.$refs['wms-filter']
 * @param key 筛选下拉 key 值，比如 zone_sector_list
 * @param allValue 全选 ALL 值，默认 -1
 */
export const filterMultiSelectedChange = (ref, key, allValue = -1) => {
    if (ref.filterData[key]) {
        const arr = [...ref.filterData[key]];
        arr.some((item, index) => {
            if (item === allValue && index !== 0) {
                ref.filterData[key] = [item];
                return true;
            }
            else if (item === allValue && index === 0 && arr.length > 1) {
                ref.filterData[key] = arr.slice(1);
                return true;
            }
        });
    }
    else {
        throw new Error(`${key} has not exit`);
    }
};
/**
 * 计算字符串的字符长度
 *
 * @param {string} str - 需要计算字符长度的字符串
 */
export const getCharacterLength = (str) => {
    return str.length;
};
/**
 * 判断字符串长度是否大于规定数字
 *
 * @param str - 字符串
 * @param num - 不超过需求限制字符串长度
 */
export const validateStrLength = (str, num) => {
    return str.length > num;
};
export const isShowMbn = () => {
    const whs_setting = safeGet(store, 'state.user.userInfo.whs_setting');
    const whs = getCookie('setting_whs_v2') || localStorage.getItem('currentWh');
    if (!whs_setting || !whs)
        return false;
    let currentWhs;
    whs_setting.some((item) => {
        if (item.whs_id === whs) {
            currentWhs = item;
            return true;
        }
    });
    if (!currentWhs)
        return false;
    return currentWhs.is_show_mbn === 1;
};
export const timeFormat = (val) => {
    if (val === 0 || val === undefined) {
        return 0;
    }
    else if (val < 3600) {
        return Math.ceil(val / 60) + 'm';
    }
    else {
        const hour = Math.floor(val / 3600);
        const min = Math.ceil((val % 3600) / 60);
        return `${hour}h ${min > 0 ? min + 'm' : ''}`;
    }
};
/**
 * 根据 DOM 元素截图
 *
 * @param dom 需要下载的 dom 元素
 * @param fileName - 下载的文件名称
 * @param option - 配置项
 */
export const downloadImage = function (dom, fileName, option = {}) {
    html2canvas(dom, {
        backgroundColor: option?.backgroundColor || null,
        useCORS: option?.useCORS || true,
        scale: option?.scale || 1,
    }).then((canvas) => {
        //截图用img元素承装，显示在页面的上
        const img = new Image();
        img.src = canvas.toDataURL('image/jpeg'); // toDataURL :图片格式转成 base64
        const a = document.createElement('a');
        a.href = canvas.toDataURL('image/jpeg');
        a.download = fileName;
        a.click();
    });
};
// 整数添加千分位逗号
export const toThousands = (number) => {
    let num = (number || 0).toString(), result = '';
    while (num.length > 3) {
        result = ',' + num.slice(-3) + result;
        num = num.slice(0, num.length - 3);
    }
    if (num) {
        result = num + result;
    }
    return result;
};
//自定义封装批量打印，根据每张打印内容的print_type循环打印
export const batchPrint = async (print_list) => {
    for (const item of print_list) {
        let itemOptions = {
            width: item.width,
            height: item.height,
        };
        if (item.options) {
            itemOptions = { ...itemOptions, ...item.options };
        }
        let print_type = 'base64';
        if (item.print_type == 2) {
            print_type = 'html';
        }
        const res = await printer.general_print(item.print_content, print_type || '', item.conf_name, itemOptions, false);
        if (res.retcode !== 0) {
            return {
                retcode: -1,
                message: 'error',
            };
        }
    }
    return {
        retcode: 0,
        message: 'success',
    };
};
// 判断密码是否为弱密码
export const isWeakPassword = (password) => {
    // const reg =
    //   /^(?=\S{8,})(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]/;
    const reg = /^(?=.*[a-z])(?=.*[A-Z]).+$/;
    return !reg.test(password);
};
/**
 * 筛选器默认会将多选数据转化成逗号隔开的字符串，比如 A,B。
 * 此函数将这种数据转成数组
 * @param value string
 */
export const transCommaStringDataToArray = (value) => {
    return typeof value === 'string' ? value.split(',') : value;
};
export const getUseDeviceInbound = async () => {
    const whs = getCookie('setting_whs_v2') || '';
    const { data } = await getDevConf({
        conf_key: 'Use_Device_Inbound',
        whs_id: whs,
    });
    return data.conf_value === '1';
};
// enums.systemEnums.InboundType
export const PURCHASE_INBOUND = 0;
export const REPLACE_INBOUND = 1;
export const BUYER_RETURN = 2;
export const FAILED_DELIVERY = 3;
export const MOVE_INBOUND = 4;
export const RE_INBOUND = 5;
export const PO_INBOUND_FIRST_PURCHASE = 6;
export const PO_INBOUND_REPLENISHMENT = 7;
export const RE_INBOUND_RTS = 8;
export const RE_INBOUND_OTHERS = 9;
export const getInboundTypeSelections = (useDeviceInbound) => {
    // https://confluence.shopee.io/pages/viewpage.action?pageId=1784701756
    const noUseDeviceInboundOptionValue = [
        PURCHASE_INBOUND,
        REPLACE_INBOUND,
        RE_INBOUND,
    ];
    const useDeviceInboundOptionValue = [
        REPLACE_INBOUND,
        PO_INBOUND_FIRST_PURCHASE,
        PO_INBOUND_REPLENISHMENT,
        RE_INBOUND_RTS,
        RE_INBOUND_OTHERS,
    ];
    const filterOptions = getSafeSelectOptions(store.state.enums, 'systemEnums.InboundType').filter((item) => {
        if (!useDeviceInbound) {
            if (noUseDeviceInboundOptionValue.includes(+item.value)) {
                return true;
            }
        }
        else {
            if (useDeviceInboundOptionValue.includes(+item.value)) {
                return true;
            }
        }
    });
    return filterOptions;
};
export var ABCConfigEnums;
(function (ABCConfigEnums) {
    ABCConfigEnums[ABCConfigEnums["SA"] = 4] = "SA";
    ABCConfigEnums[ABCConfigEnums["UNDEFINED"] = 0] = "UNDEFINED";
    ABCConfigEnums[ABCConfigEnums["EXCLUDED"] = 5] = "EXCLUDED";
    ABCConfigEnums["ALL"] = "";
})(ABCConfigEnums || (ABCConfigEnums = {}));
export const getABCSelections = async (state, config, excludeConfig) => {
    const res = await searchABCConfig();
    if (res?.data?.abc_configs) {
        const selections = res?.data?.abc_configs
            .map((value) => {
            return {
                label: getRenderValue(state, 'enums.systemEnums.ABCClassification', value),
                value,
            };
        })
            .filter((item) => {
            if (excludeConfig?.[item.value]) {
                return false;
            }
            else {
                return true;
            }
        });
        /* if (config?.excluded) {
          selections.push({
            label: i18n('Excluded'),
            value: 5,
          });
        }
        if (config?.sa) {
          selections.push({
            label: 'SA',
            value: 4,
          });
        }
        if (config?.undefined) {
          selections.unshift({
            label: 'Undefined',
            value: 0,
          });
        } */
        if (config?.all) {
            selections.unshift({
                label: i18n('All'),
                value: '',
            });
        }
        return selections;
    }
    return [];
};
// 存储一些常见的国家代码和货币代码
const countryCurrencyMap = {
    CN: 'CNY',
    US: 'USD',
    JP: 'JPY',
    EU: 'EUR',
    GB: 'GBP',
    IN: 'INR',
    SG: 'SGD',
    PH: 'PHP',
    MM: 'MMK',
    LA: 'LAK',
    BN: 'BND',
    TH: 'THB',
    VN: 'VND',
    MY: 'MYR',
    ID: 'IDR',
    KH: 'KHR',
    BR: 'BRL',
    MX: 'MXN',
    CA: 'CAD',
    CH: 'CHF',
    NZ: 'NZD',
    KR: 'KRW',
    RU: 'RUB',
    ZA: 'ZAR',
    TR: 'TRY', // 土耳其里拉
    // 添加其他的国家代码和货币代码
};
// 根据国家代码返回货币代码
function getCurrencyByCountry(country) {
    if (country in countryCurrencyMap) {
        return countryCurrencyMap[country];
    }
    else {
        // 返回一个兜底的货币代码
        return i18n('Local Currency');
    }
}
/* 根据操作仓库返回当前仓库的货币代码 */
export const getCurrencyByWhsArea = function () {
    const code = getCookie('setting_whs_v2') || '';
    const area = code.slice(0, 2).toUpperCase() || '';
    return getCurrencyByCountry(area);
};
export const getParkZoneSelection = async (data = {}) => {
    try {
        const res = await getParkZoneTree(data);
        if (res.retcode === 0) {
            const { park_list } = res.data;
            let parkSelections = park_list &&
                park_list.map((item) => {
                    return {
                        value: item.park_id,
                        label: item.park_name,
                        children: item.sector_list &&
                            item.sector_list.map((park) => {
                                return {
                                    value: park.zone_sector_id,
                                    label: park.zone_sector_name,
                                    children: park.zone_cluster &&
                                        park.zone_cluster.map((cluster) => {
                                            return {
                                                value: cluster.zone_cluster_id,
                                                label: cluster.zone_cluster_name,
                                                children: cluster.zone &&
                                                    cluster.zone.map((zone) => {
                                                        return {
                                                            value: zone.zone_id,
                                                            label: zone.zone_id,
                                                        };
                                                    }),
                                            };
                                        }),
                                };
                            }),
                    };
                });
            parkSelections = parkSelections.filter((item) => {
                return item.children.length > 0;
            });
            for (let i = 0; i < parkSelections.length; i++) {
                parkSelections[i].children = parkSelections[i].children.filter((item) => {
                    return item.children;
                });
                for (let j = 0; j < parkSelections[i].children.length; j++) {
                    parkSelections[i].children[j].children = parkSelections[i].children[j].children.filter((item) => {
                        return item.children;
                    });
                }
                parkSelections[i].children = parkSelections[i].children.filter((item) => {
                    return item.children.length > 0;
                });
            }
            parkSelections = parkSelections.filter((item) => {
                return item.children.length > 0;
            });
            return parkSelections;
        }
    }
    catch (e) {
        console.log('error', e);
        return [];
    }
};
export const parkZoneSelectionFormatHelper = (park_list = [], config = {
    needDisabled: true,
    disabledKey: '',
    ignoreList: [],
    value: [],
}) => {
    try {
        const bindValue = [];
        let parkSelections = park_list &&
            park_list.map((item) => {
                return {
                    value: item.park_id,
                    label: item.park_name,
                    children: item.sector_list &&
                        item.sector_list.map((park) => {
                            return {
                                value: park.zone_sector_id,
                                label: park.zone_sector_name,
                                children: park.zone_cluster &&
                                    park.zone_cluster.map((cluster) => {
                                        // 第三级需要根据第四级的 disable 数量来判断是否需要 disable 掉第三极节点
                                        let disabledCount = 0;
                                        // 第四级节点数组
                                        const children = (cluster.zone || []).map((zone) => {
                                            // 判断是否包含了已经绑定的值，需要返回绑定的值 —— bindValue
                                            if (config.value?.includes(zone.zone_id)) {
                                                bindValue.push([
                                                    item.park_id,
                                                    park.zone_sector_id,
                                                    cluster.zone_cluster_id,
                                                    zone.zone_id,
                                                ]);
                                            }
                                            // 判断第四级节点是否被使用（is_used 或其他 key)，设置为 disable
                                            const disabled = config.needDisabled &&
                                                config.disabledKey &&
                                                !config.ignoreList?.includes(zone.zone_id)
                                                ? zone[config.disabledKey]
                                                : undefined;
                                            if (disabled) {
                                                // 第四级节点 disable，数量+1
                                                disabledCount++;
                                            }
                                            return {
                                                // 返回第四级节点
                                                value: zone.zone_id,
                                                label: zone.zone_id,
                                                disabled,
                                            };
                                        });
                                        return {
                                            // 返回第三极节点
                                            value: cluster.zone_cluster_id,
                                            label: cluster.zone_cluster_name,
                                            disabled: (cluster.zone || []).length === disabledCount,
                                            children,
                                        };
                                    }),
                            };
                        }),
                };
            });
        parkSelections = parkSelections.filter((item) => {
            return item.children.length > 0;
        });
        for (let i = 0; i < parkSelections.length; i++) {
            parkSelections[i].children = parkSelections[i].children.filter((item) => {
                return item.children && item.children.length;
            });
            for (let j = 0; j < parkSelections[i].children.length; j++) {
                let disabledCount = 0;
                parkSelections[i].children[j].children = parkSelections[i].children[j].children.filter((item) => {
                    if (item.children && item.children.length && item.disabled) {
                        disabledCount++;
                    }
                    return item.children && item.children.length;
                });
                parkSelections[i].children[j].disabled =
                    parkSelections[i].children[j].children.length === disabledCount;
            }
            let disabledCount = 0;
            parkSelections[i].children = parkSelections[i].children.filter((item) => {
                if (item.children.length > 0 && item.disabled) {
                    disabledCount++;
                }
                return item.children.length > 0;
            });
            parkSelections[i].disabled =
                parkSelections[i].children.length === disabledCount;
        }
        parkSelections = parkSelections.filter((item) => {
            return item.children.length > 0;
        });
        return {
            parkSelections,
            bindValue,
        };
    }
    catch (e) {
        console.log('error', e);
        return {
            parkSelections: [],
            bindValue: [],
        };
    }
};
/** 除去空节点 */
export const removeEmptyNode = (cascaderOptions) => {
    return cascaderOptions.filter((cascaderOption) => {
        if (!cascaderOption.children) {
            return true;
        }
        cascaderOption.children = removeEmptyNode(cascaderOption.children);
        return cascaderOption.children.length > 0;
    });
};
/** 找到树的叶子结点路径 */
export const findTreePath = (cascaderOptions, value, result) => {
    for (let i = 0; i < cascaderOptions.length; i++) {
        result.push(cascaderOptions[i].value);
        if (cascaderOptions[i].children) {
            // 为非叶子结点
            const res = findTreePath(cascaderOptions[i].children, value, result);
            if (res.length > 0) {
                // 已经找到目标节点，将结果透传出去
                return res;
            }
        }
        else {
            // 为叶子结点
            if (cascaderOptions[i].value === value) {
                // 找到叶子结点
                return result;
            }
        }
        result.pop();
    }
    return [];
};
/** 获取级联选择器多选的值 */
export const getInputCascaderValues = (value, depth) => {
    if (!value || typeof value !== 'string') {
        return [];
    }
    return value.split(',').filter((val, index) => (index + 1) % depth === 0);
};
export function handleSearchForm(param) {
    return Object.keys(param).reduce((pre, cur) => {
        if (typeof param[cur] === 'string' && param[cur].trim() === '') {
            pre[cur] = undefined;
        }
        else {
            pre[cur] = param[cur];
        }
        return pre;
    }, {});
}
/** 用于 input-cascader 展示最后一个 tag */
export function showInputCascaderLastTag(checkedNodesList) {
    return checkedNodesList.map((node) => [
        (node && node[node.length - 1]) ?? '',
    ]);
}
export function showLastTag(checkedNodesList) {
    return checkedNodesList.map((node) => [
        (node && node[node.length - 1]) ?? '',
    ]);
}
