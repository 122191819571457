/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { Suspense, FC } from 'react';
import { ConfigProvider } from 'ssc-ui-react';
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import styles from './index.module.less';
import I18nProvider from '@/react-app/utils/i18n/provider';
import { ProConfigProvider } from 'react-pro-components';
import { getEnv } from '@/utils/getEnv';

const isLive = getEnv() === 'live';
function getStoreServiceUrl() {
  let storeServiceBaseUrl = 'https://storeservice.ssc.shopee.sg';
  if (!isLive) {
    storeServiceBaseUrl = 'https://storeservice.ssc.test.shopee.sg';
  }
  const url = new URL(storeServiceBaseUrl);
  url.pathname = '/api/store_service/save_info/';
  return url.toString();
}

const Wrapper: FC = ({ children }) => {
  return (
    /* eslint-disable @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    <Suspense fallback={''}>
      <I18nProvider>
        <ConfigProvider prefixCls="ssc-react" rcPrefixCls="ssc-react-rc">
          <ProConfigProvider
            storeService={{
              emailKey: 'wms_display_name',
              baseBt: 'SBS',
              isLive,
              baseSbt: 'WMSV2',
              storeServiceUrl: getStoreServiceUrl(),
            }}
          >
            <div className={styles.root} id="react-wrapper">
              {children}
            </div>
          </ProConfigProvider>
        </ConfigProvider>
      </I18nProvider>
    </Suspense>
  );
};

export default Wrapper;
