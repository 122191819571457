import debounce from 'lodash.debounce';
import Vue from 'vue';
export function CatchError(loadingName) {
    return function (target, name, descriptor) {
        const fn = target[name];
        descriptor.value = async function (...args) {
            if (loadingName) {
                this[loadingName] = true;
            }
            try {
                return await fn.call(this, ...args);
            }
            catch (error) {
                console.log('error', error);
            }
            finally {
                if (loadingName) {
                    this[loadingName] = false;
                }
            }
        };
        return descriptor;
    };
}
export function Debounce(delay, config = {}) {
    return (target, prop) => {
        return {
            value: debounce(target[prop], delay, config),
        };
    };
}
export function Confirmation(config) {
    return function (target, name, descriptor) {
        const fn = descriptor.value;
        let _instance = null;
        descriptor.value = function (...args) {
            let _config = null;
            if (typeof config === 'function') {
                _config = config.call(this);
            }
            else {
                _config = config;
            }
            const confirmType = _config?.type || '$confirm';
            let showCancelButton = true;
            if (_config?.options &&
                _config.options.showCancelButton !== undefined) {
                showCancelButton = _config.options.showCancelButton;
            }
            Vue.prototype[confirmType](_config?.message, _config?.title, Object.assign({
                showCancelButton: showCancelButton,
                beforeClose: (action, instance, done) => {
                    _instance = instance;
                    instance.confirmButtonLoading = true;
                    if (action === 'confirm') {
                        fn.call(this, done, instance, ...args);
                    }
                    else {
                        done();
                    }
                },
            }, _config?.options || {}))
                .then(() => {
                _instance.confirmButtonLoading = false;
            })
                .catch(() => {
                _instance.confirmButtonLoading = false;
            });
        };
        return descriptor;
    };
}
export function Validate(refName) {
    return function (target, name, descriptor) {
        const fn = descriptor.value;
        descriptor.value = function (...args) {
            // 将触发校验的代码封装在此
            this.$refs[refName].validate((valid) => {
                if (valid) {
                    fn.call(this, ...args);
                }
                else {
                    return false;
                }
            });
        };
    };
}
