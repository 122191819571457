import { Loader } from '@spx-portal/plugin-loader';
import router from './src/router';
import { getDevConfList } from './src/api/basic/displaySetting';
import { getCookie } from './src/utils/cookie';
export const runLoader = async () => {
    const whs = getCookie('setting_whs_v2') || '';
    const { data } = await getDevConfList({
        conf_key: ['wms-cctv-version', 'wms-lh-version'],
        whs_id: whs,
    });
    const [wmsCctvVersion, wmsLhVersion] = data.conf_list;
    const isDev = process.env.NODE_ENV === 'development';
    const curEnv = process.env.VUE_APP_RUN_ENV === 'development'
        ? 'dev'
        : process.env.VUE_APP_RUN_ENV;
    window.__curEnv = curEnv;
    const loader = new Loader({
        isDev: process.env.NODE_ENV === 'development',
        router,
        preLoadModules: [
            { name: 'inwork', type: 'react', load: true, modules: ['module'] },
            {
                name: 'linehaul',
                type: 'vue',
                modules: ['module'],
                globalName: 'WMSLinehaulTimestamp',
                entryUrl: isDev
                    ? 'http://localhost:8010/WMSRemoteEntry.js'
                    : `/v2/linehaul/linehaul${wmsLhVersion.conf_value}RemoteEntry.js`,
                load: true,
                loadType: 'sandbox',
                sandbox: {
                    activeRule: '/v2/linehaul',
                    // @ts-ignore
                    storeConfig: {
                        mode: 'isolated',
                    },
                    routerConfig: {
                        onRouteChange: (route) => {
                            if (route.path.includes('hubLinehaulTrips')) {
                                // @ts-ignore
                                APP_CONTEXT.store.dispatch('getRouteFromAndTo', { to: route });
                            }
                        },
                    },
                    meta: {
                        title: 'Linehaul',
                    },
                    libs: ['vue@2.6.14', 'ssc-ui-vue@2.1.10', 'vue-i18n@8.24.4'],
                },
            },
        ],
        moduleGlobalMap: {
            react: { inwork: 'SPXInworkReactCCTVModule' },
        },
        moduleEntryFileNameMap: {
            SPXInworkReactCCTVModule: `cctv${wmsCctvVersion.conf_value}remoteEntry.react.js`,
        },
        digging: false,
        allRouteModules: [],
    });
    loader.run((option) => {
        console.log(option);
    });
};
