import Vue from 'vue';
import axios from 'axios';
import { Loading } from 'ssc-ui-vue';
import { APIWatcher, SLAWatcher } from './qms';
import { threePlApiReport } from './threePlApiReport';
// import { UatuAxiosWatcher } from './uatu';
import { i18n } from '@/i18n';
import WeakNetworkMonitor from './weakNetworkMonitor';
function authSPXToWMS(response) {
    try {
        // 仅针对 spx-cross 的接口进行处理
        // 规则 1：
        // 将 response.status 为 401 转换为 response.status 200,
        // 将 data.retcode 为 401 转换为 -100405
        // 规则 2：
        // response.data.retcode 100301007 转为 -100401
        if (response.config.url.includes('spx-cross.ssc')) {
            if (response.status === 401 || response.data.retcode === 401) {
                response.status = 200;
                response.data.retcode = -100405;
                Vue.prototype.$message.error(i18n(response.data.message, [], true), true);
                return Promise.reject(response);
            }
            if (response.data.retcode === 100301007) {
                response.data.retcode = -100401;
                Vue.prototype.$message.error(i18n(response.data.message, [], true), true);
                return Promise.reject(response);
            }
            if (response.data.retcode !== 0) {
                try {
                    const oriObj = JSON.parse(response.data.message || '{}');
                    const { message, retcode } = oriObj || {};
                    // '{"retcode":0,"message":"success"}'
                    if (message)
                        response.data.message = message;
                    if (typeof retcode === 'number')
                        response.data.retcode = retcode;
                    return Promise.resolve(response);
                }
                catch (error) {
                    Vue.prototype.$message.error(i18n(response.data.message, [], true), true);
                    return Promise.reject(response);
                }
            }
            return response;
        }
        return response;
    }
    catch (error) {
        console.error('authSPXToWMS error', error);
        return response;
    }
}
class Request {
    constructor() {
        this.baseURL = '';
        this.service = axios;
        this.pendingRequest = new Map();
        this.CancelToken = axios.CancelToken;
        this.axiosRequestConfig = {};
        // 允许重复请求白名单
        this.whiteList = [
            '/api/v2/apps/pii/get_pii_data',
            '/api/v2/configuration/get_dev_conf',
            '/api/v2/apps/process/outbound/package/get_order_inner_package',
            '/api/v2/apps/config/asnquota/search_asn_quota_config',
            '/api/v2/apps/process/outbound/instantdashbord/search_instant_order_list',
            '/api/v2/apps/process/inbound/reprocess/get_task_type_list',
        ];
        this.requestConfig();
        this.service = axios.create(this.axiosRequestConfig);
        // 增加API埋点，在axios拦截处理前埋点使用该方法
        APIWatcher(this.getService());
        // 增加 SLA 业务上报
        SLAWatcher(this.getService());
        // UatuAxiosWatcher(this.getService());
        this.interceptorsRequest();
        this.interceptorsResponse();
        // @ts-ignore
        this.axiosInstance = this.service;
    }
    static getInstance() {
        // 如果存在instance实例直接返回，如果没有，实例化后返回
        this._instance || (this._instance = new Request());
        return this._instance;
    }
    getService() {
        return this.service;
    }
    clearRequestList() {
        Array.from(this.pendingRequest.values()).forEach((config) => {
            config.loadingService && config.loadingService.close();
        });
        this.pendingRequest.clear();
    }
    requestConfig() {
        this.axiosRequestConfig = {
            baseURL: this.baseURL,
            timeout: 60000,
            withCredentials: true,
            xsrfCookieName: 'csrftoken',
            xsrfHeaderName: 'X-CSRFToken',
        };
    }
    interceptorsRequest() {
        this.service.interceptors.request.use((config) => {
            config.headers['X-CCTV-Tenant-Id'] = 'WMS';
            this.addTrimForStringValue(config);
            this.checkPendingRequest(config);
            threePlApiReport(config);
            WeakNetworkMonitor.requestTime(config);
            return config;
        }, (error) => {
            this.clearRequestList();
            return Promise.reject(error);
        });
    }
    interceptorsResponse() {
        this.service.interceptors.response.use(authSPXToWMS);
        this.service.interceptors.response.use((response) => {
            response.config &&
                response.config.loadingService &&
                response.config.loadingService.close();
            WeakNetworkMonitor.trackRttTime(response);
            const requestKey = response.config.requestKey;
            this.pendingRequest.delete(requestKey);
            if (response.data.retcode === -100405) {
                if (location.href.endsWith('login.html'))
                    return;
                location.href =
                    '/v2/tob/login' +
                        '?redirect_url=' +
                        encodeURIComponent(location.href);
                return Promise.reject(response);
            }
            if (response.data.retcode &&
                !response.config.noError &&
                !response.config.shouldNotNotifyError &&
                !(response.config.noErrorCode &&
                    response.config.noErrorCode.includes(response.data.retcode))) {
                if (response.data.message) {
                    // Vue.prototype.$message({
                    //   showClose: true,
                    //   message: i18n(response.data.message),
                    //   type: 'error',
                    // });
                    Vue.prototype.$message.error(i18n(response.data.message, [], true), true);
                }
                return Promise.reject(response.data);
            }
            // 处理有 noError 和 noErrorCode 配置时，reject error
            if (response.data.retcode !== 0 &&
                response.data.retcode !== undefined) {
                return Promise.reject(response.data);
            }
            return Promise.resolve(response.data);
        }, (error) => {
            if (axios.isCancel(error)) {
                console.warn(error);
                return Promise.reject(error);
            }
            this.clearRequestList();
            return Promise.reject(error);
        });
    }
    checkPendingRequest(config) {
        const requestKey = `${config.url}/${JSON.stringify(config.params)}/${JSON.stringify(config.data)}&request_type=${config.method}`;
        if (this.pendingRequest.has(requestKey)) {
            config.cancelToken = new this.CancelToken((cancel) => {
                cancel(`重复的请求被主动拦截: ${requestKey}`);
            });
        }
        else {
            if (!this.whiteList.includes(config.url)) {
                this.pendingRequest.set(requestKey, config);
            }
            config.requestKey = requestKey;
            if (config.loadingTarget) {
                this.addLoading(config);
            }
        }
    }
    addLoading(config) {
        if (!document.querySelector(config.loadingTarget))
            return;
        config.loadingService = Loading.service({
            target: config.loadingTarget,
        });
    }
    addTrimForStringValue(config) {
        function addTrim(data) {
            if (Object.prototype.toString.call(data) === '[object Object]') {
                Object.keys(data).forEach((key) => {
                    if (typeof data[key] === 'string') {
                        data[key] = data[key].trim();
                    }
                });
            }
        }
        if (config.params) {
            addTrim(config.params);
        }
        if (config.data) {
            addTrim(config.data);
        }
    }
    async post(url, data = {}, userConfig = {}, otherConfig = {}) {
        const result = await this.service({
            method: 'post',
            url,
            data,
            noError: false,
            ...userConfig,
            ...otherConfig,
        });
        return result;
    }
    async get(url, params = {}, userConfig = {}, otherConfig = {}) {
        const result = await this.service({
            method: 'get',
            url,
            params: params.params || {},
            noError: false,
            ...userConfig,
            ...otherConfig,
        });
        return result;
    }
}
export default Request.getInstance();
