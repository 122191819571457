const sscLanguageKey = ['zh-Hans', 'zh-Hant', 'ph', 'ms-my', 'es-MX'];
// tsp 缩写与组件库翻译包对应 map
const sscLanguageMap = new Map([
    ['zh-Hans', 'zh-CN'],
    ['zh-Hant', 'zh-TW'],
    ['ph', 'tl'],
    ['ms-my', 'ms'],
    ['es-MX', 'pt'],
    ['pt-BR', 'pt-BR'],
]);
// 获取组件库语言包
export async function getSSCLanguage(lang) {
    try {
        let sscLocale = lang;
        if (sscLanguageKey.includes(lang))
            sscLocale = sscLanguageMap.get(sscLocale) || sscLocale;
        const sscRes = await import('ssc-ui-vue/es/locale');
        const sscLang = sscRes.langs[sscLocale];
        return { ...sscLang };
    }
    catch (e) {
        console.log('e', e);
    }
}
